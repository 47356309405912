import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {
  Col,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {GatsbySEO} from '~/gatsby/components/seo'
import {WebForm} from '~/web-form/components/form'
import {createReplace} from '../../renderer'
import {linkedItems} from '../../util'
import {KenticoBodyItem} from '../body-item'
import {KenticoGlobalScriptData} from '../global-script'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoFormPageData} from './data'

interface Props {
  page: KenticoFormPageData
  globalScripts: KenticoGlobalScriptData
}

/** Kentico type data. */
export {KenticoFormPageData}

const Container = styled(BootstrapContainer)`
  && {
    padding: 0;
  }
`

const PageTitleCol = styled(Col)`
  padding: ${({theme}) => theme.spacers[4]};

  h1,
  h2,
  h3,
  h4 {
    line-height: 1.5;
  }
`

const Sidebar = styled(Col)`
  > * {
    margin-bottom: ${({theme}) => theme.spacers[3]};
  }
`

const FORM_TYPE_LOOKUP = {
  prequal_cash_advance: 'CASH_ADVANCE',
  prequal_installment: 'INSTALLMENT',
  prequal_title: 'TITLE',
} as const

/**
 * Render form page from Kentico data.
 * @return React component
 */
export const KenticoFormPage = ({globalScripts, page: formPage}: Props) => {
  let editLink
  if (!UUID.is(formPage.system.name)) {
    editLink = <KenticoPreviewBodyLink contentItem={formPage.system.id} />
  }

  const redirectLinks = linkedItems(formPage.elements.submissionLinks)

  // Determine form type
  const {elements} = formPage
  if (elements.formType.value.length === 0) {
    throw new Error('Invalid form type')
  }
  const formType = elements.formType.value[0].codename
  const type = FORM_TYPE_LOOKUP[formType]

  const {
    body,
    mainText,
    postBody,
    preBody,
    privacyConsent,
    robots,
    scripts,
    tcpa,
    textConsent,
    description: {value: description},
    title: {value: title},
  } = elements
  const privacyRichText = <KenticoRichText data={privacyConsent} />
  const tcpaRichText = <KenticoRichText data={tcpa} />
  const textRichText = <KenticoRichText data={textConsent} />
  return (
    <>
      <GatsbySEO
        description={description}
        globalScripts={globalScripts}
        page={formPage}
        pageScripts={scripts}
        replace={createReplace}
        robots={robots}
        title={title}
      />
      <KenticoBodyItem data={linkedItems(preBody)} />
      <Container>
        <Row noGutters>
          <Col lg="8">
            {editLink}
            <PageTitleCol>
              <KenticoRichText data={mainText} />
            </PageTitleCol>
            <WebForm
              privacyConsent={privacyRichText}
              submissionLinks={redirectLinks}
              tcpa={tcpaRichText}
              textConsent={textRichText}
              type={type}
            />
          </Col>
          <Sidebar lg="4">
            <KenticoBodyItem data={linkedItems(body)} />
          </Sidebar>
        </Row>
      </Container>
      <KenticoBodyItem data={linkedItems(postBody)} />
    </>
  )
}
